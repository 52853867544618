import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ProductsPage/Banner/Banner";
import Expand from "../../components/ProductsPage/Expand/Expand";
import Faq from '../../components/ProductsPage/Faq/Faq';
import AvailableMarkets from "../../components/ProductsPage/AvailableMarkets/AvailableMarkets";

const ProductsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Charm Finance · Products</title>
        <meta name="title" content="Learn Forex Trading from Experts - Charm Finance"/>
        <meta property="og:title" content="Learn Forex Trading from Experts - Charm Finance"/>
        <meta property="twitter:title" content="Learn Forex Trading from Experts - Charm Finance"/>

        <meta name="description" content="Whether you are a beginner or an experienced trader, Charm Finance offers a wide range of educational resources to help you improve your trading skills. Join our comprehensive training programs and webinars to become a successful trader."/>
        <meta property="og:description" content="Whether you are a beginner or an experienced trader, Charm Finance offers a wide range of educational resources to help you improve your trading skills. Join our comprehensive training programs and webinars to become a successful trader."/>
        <meta property="twitter:description" content="Whether you are a beginner or an experienced trader, Charm Finance offers a wide range of educational resources to help you improve your trading skills. Join our comprehensive training programs and webinars to become a successful trader."/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Expand />
        <AvailableMarkets />
        <Faq />        
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ProductsPage;
