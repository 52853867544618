import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AboutPage/Banner/Banner";
import ForEveryone from "../../components/AboutPage/ForEveryone/ForEveryone";
import OurAdvantages from "../../components/AboutPage/OurAdvantages/OurAdvantages";
import PowerOf from "../../components/AboutPage/PowerOf/PowerOf";
import Tabs from "../../components/AboutPage/Tabs/Tabs";
import OurAwards from "../../components/AboutPage/OurAwards/OurAwards";
import Faq from "../../components/AboutPage/Faq/Faq";
import OurHistory from "../../components/AboutPage/OurHistory/OurHistory";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Charm Finance · About Us</title>
        <meta name="title" content="Discover Charm Finance Forex Broker's Story"/>
        <meta property="og:title" content="Discover Charm Finance Forex Broker's Story"/>
        <meta property="twitter:title" content="Discover Charm Finance Forex Broker's Story"/>

        <meta name="description" content="Learn more about Charm Finance's history, values, and mission. Find out why we are one of the most trusted and respected forex brokers in the industry and how we can help you achieve your trading goals."/>
        <meta property="og:description" content="Learn more about Charm Finance's history, values, and mission. Find out why we are one of the most trusted and respected forex brokers in the industry and how we can help you achieve your trading goals."/>
        <meta property="twitter:description" content="Learn more about Charm Finance's history, values, and mission. Find out why we are one of the most trusted and respected forex brokers in the industry and how we can help you achieve your trading goals."/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <OurHistory />
        <ForEveryone />
        <OurAdvantages />
        <Tabs />
        <PowerOf />
        <OurAwards />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
