import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/PricingPage/Banner/Banner";
import UnderBanner from "../../components/PricingPage/UnderBanner/UnderBanner";
import GeneralInfo from "../../components/PricingPage/GeneralInfo/GeneralInfo";
import Safely from "../../components/PricingPage/Safely/Safely";
import OptimalTrading from "../../components/PricingPage/OptimalTrading/OptimalTrading";
import Faq from "../../components/PricingPage/Faq/Faq";

const PricingPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Charm Finance · Pricing</title>
        <meta name="title" content="Trade with the Best Forex Broker - Charm Finance"/>
        <meta property="og:title" content="Trade with the Best Forex Broker - Charm Finance"/>
        <meta property="twitter:title" content="Trade with the Best Forex Broker - Charm Finance"/>

        <meta name="description" content="Trade Forex, Cryptocurrencies, Stocks, Indices, and more with Charm Finance. Benefit from our tight spreads, fast execution, and advanced trading tools to maximize your profits."/>
        <meta property="og:description" content="Trade Forex, Cryptocurrencies, Stocks, Indices, and more with Charm Finance. Benefit from our tight spreads, fast execution, and advanced trading tools to maximize your profits."/>
        <meta property="twitter:description" content="Trade Forex, Cryptocurrencies, Stocks, Indices, and more with Charm Finance. Benefit from our tight spreads, fast execution, and advanced trading tools to maximize your profits."/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <GeneralInfo />
        <Safely />
        <OptimalTrading />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PricingPage;
