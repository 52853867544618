import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/MainComponents/Banner/Banner";
import Trust from "../../components/MainComponents/Trust/Trust";
import Competitive from "../../components/MainComponents/Competitive/Competitive";
import Included from "../../components/MainComponents/Included/Included";
import Open from "../../components/MainComponents/Open/Open";
import Best from "../../components/MainComponents/Best/Best";
import Crypto from "../../components/MainComponents/Crypto/Crypto";
import Faq from "../../components/MainComponents/Faq/Faq";
import About from "../../components/MainComponents/About/About";
import OurAwards from "../../components/AboutPage/OurAwards/OurAwards";
// import Rew

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Charm Finance</title>
        <meta
          name="title"
          content="Trade with Confidence with Charm Finance Forex Broker"
        />
        <meta
          property="og:title"
          content="Trade with Confidence with Charm Finance Forex Broker"
        />
        <meta
          property="twitter:title"
          content="Trade with Confidence with Charm Finance Forex Broker"
        />

        <meta
          name="description"
          content="Discover the benefits of trading with Charm Finance, a reliable and transparent forex broker. Join us and access a wide range of financial instruments, top-notch trading platforms, and exceptional customer service."
        />
        <meta
          property="og:description"
          content="Discover the benefits of trading with Charm Finance, a reliable and transparent forex broker. Join us and access a wide range of financial instruments, top-notch trading platforms, and exceptional customer service."
        />
        <meta
          property="twitter:description"
          content="Discover the benefits of trading with Charm Finance, a reliable and transparent forex broker. Join us and access a wide range of financial instruments, top-notch trading platforms, and exceptional customer service."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Trust />
        <Competitive />
        <Included />
        <Open />
        <Best />
        <Crypto />
        <About />
        <OurAwards />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
