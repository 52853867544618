import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/PromotionsPage/Banner/Banner";
import BestBonus from "../../components/PromotionsPage/BestBonus/BestBonus";
import TradeMore from "../../components/PromotionsPage/TradeMore/TradeMore";
import FirstBonus from "../../components/PromotionsPage/FirstBonus/FirstBonus";
import Faq from "../../components/PromotionsPage/Faq/Faq";

const PromotionsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Charm Finance · Promotions</title>
        <meta name="title" content="Boost Your Profits with Charm Finance Promotions"/>
        <meta property="og:title" content="Boost Your Profits with Charm Finance Promotions"/>
        <meta property="twitter:title" content="Boost Your Profits with Charm Finance Promotions"/>

        <meta name="description" content="Take advantage of Charm Finance's attractive promotions and bonuses to enhance your trading experience. Enjoy higher leverage, cashback, and other exclusive offers that can increase your profits significantly."/>
        <meta property="og:description" content="Take advantage of Charm Finance's attractive promotions and bonuses to enhance your trading experience. Enjoy higher leverage, cashback, and other exclusive offers that can increase your profits significantly."/>
        <meta property="twitter:description" content="Take advantage of Charm Finance's attractive promotions and bonuses to enhance your trading experience. Enjoy higher leverage, cashback, and other exclusive offers that can increase your profits significantly."/>

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <BestBonus />
        <TradeMore />
        <FirstBonus />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PromotionsPage;
